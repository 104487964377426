import React from "react"
import { graphql } from "gatsby"

// s4n
import { injectIntl } from "gatsby-plugin-intl"
import FragmentProductIndex from '../../model/fragments/allCommerceProductDefault/product-index'
import ContextProductIndexWrapper from './../../s4n/context/ProductIndex/wrapper-class'
import ProductIndex from "./../../components/s4n/Shop"



const ComponentStateless = (props) => {
    const { data: { index }, pageContext, intl } = props;

    // console.log(pageContext)
    // console.log(index.edges)

    const propsData = {
      pageContext,
      aDataCollectionTranslated: index.edges,
    };

    return (
      <ContextProductIndexWrapper intl={intl} pageContext={pageContext}>
          <ProductIndex propsData={propsData} />
      </ContextProductIndexWrapper>
    )
}

export default injectIntl(ComponentStateless)

export const query = graphql`
    query (
        $skip: Int!, 
        $limit: Int!,
        $langcode: String!,
        $category: String!
    ) {
        index: allCommerceProduct (
            filter: {
              # this node is available in graphql database, no matter it is published/unpublished, we need to filter it out here as we need by status, title, ...
              status: {
                  eq: true
              }
              title: {
                  # ne: "SOFT4NET_DO_NOT_DELETE"
                  nin: ["SOFT4NET_DO_NOT_DELETE", "Test"]
              }
              langcode: {
                eq: $langcode
              }
              relationships: {
                field_category: {
									elemMatch: {
                    name: {
                      eq: $category
                    }
                  }
                }
              }
            }
            sort: {
              fields: [
                commerceStoreOnline___relationships___field_promoted_category_weight, 
                title,
              ]
              order: [
                DESC, 
                ASC,
              ]
            }
            limit: $limit
            skip: $skip
        ) {
            edges {
                node {
                    ...FragmentProductIndex
                }
            }
        }
    }
`